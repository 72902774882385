import React from 'react';
import './App.css';

// import '~antd/dist/antd.css';
import { connect } from 'react-redux';
import store from './store/store';
import API from './utils/API/API';
import { Outlet } from 'react-router-dom';
import Notification from './components/Notification/Notification';
import { setIsEditDesign, updateState } from './store/reducers/AuthReducer';
import CollapsibleModal from './utils/CollapsibleModal/CollapsibleModal';
import EditDesign from './components/Pages/Profile/Components/EditDesign';
import { KeycloakApp } from './utils/keycloak/KeycloakApp';
import Forbidden from './components/Pages/Authorization/Forbidden';
import { SpinPage } from './utils/SpinPage/SpinPage';
import { ProfileType } from './components/Pages/Admin/Accounts/type';
import { setTablesSettings } from './store/reducers/AbstractColumnsSlice';
import axios from 'axios';

const STATUS_FORBIDDEN = 403;
const STATUS_NOT_FOUND = 404;

class App extends React.Component<
  any,
  {
    isVerify: boolean;
    isReady: boolean;
    errorStatus: 403 | 404 | 500 | 0 | 'NetworkError';
  }
> {
  constructor(props) {
    super(props);
    this.state = {
      isVerify: false,
      isReady: false,
      errorStatus: 0,
    };
    store.subscribe(() => {
      this.forceUpdate();
    });
  }

  componentDidMount(): void {
    this.verifyProfile([STATUS_FORBIDDEN, STATUS_NOT_FOUND]);
  }

  handleErrors = (error, errorStatuses: number[]) => {
    const errorStatus = error?.response?.status;

    if (!error?.response) {
      this.setState({ errorStatus: 'NetworkError' });
    }

    if (errorStatuses.includes(errorStatus)) {
      this.setState({ errorStatus: errorStatus });
    }
  };

  getUserData = async (id: string) => {
    const response = await API.get(`api/accounts/key-cloak/${id}`, {
      notNotification: true,
    });
    const account: ProfileType = response.data.accountData;
    this.props.dispatch(updateState({ ...account }));
    this.props.dispatch(
      setTablesSettings(
        JSON.parse(account.accountProfileData?.tablesSettings || '[]')
      )
    );
    return;
  };
  verifyProfile = async (errorStatuses: number[]) => {
    try {
      const authenticated = await KeycloakApp.init({
        onLoad: 'login-required',
        responseMode: 'query',
      });
      if (authenticated && KeycloakApp.subject) {
        await this.getUserData(KeycloakApp.subject);
      }
      if (authenticated) {
        this.setState({ isReady: true });
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        this.handleErrors(error, errorStatuses);
      }
    }
  };
  isLoggedIn = () => {
    const { isReady, errorStatus } = this.state;
    return isReady && !errorStatus;
  };

  render() {
    const { profile, dispatch } = this.props;
    const { errorStatus, isReady } = this.state;
    return (
      <div className="App">
        {this.isLoggedIn() && (
          <>
            <Outlet />
            <Notification list={this.props.notificationsList} />
            {profile.isEditDesign && (
              <CollapsibleModal
                title={'Редактировать'}
                visible={profile.isEditDesign}
                onCancel={() => dispatch(setIsEditDesign(false))}
              >
                <EditDesign onCancel={() => dispatch(setIsEditDesign(false))} />
              </CollapsibleModal>
            )}
          </>
        )}
        {!isReady && <SpinPage />}
        {!!errorStatus && <Forbidden status={errorStatus} />}
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    profile: state.authReducer,
    notificationsList: state.notificationsReducer.notificationsList,
  };
};

export default connect(mapStateToProps)(App);
